import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import {
  getTripAdvisorList,
  TripAdvisorPlace,
  TripAdvisorResponse,
} from 'src/services/tripAdvisorApi'
import styles from './tripAdvisorDebounce.module.scss'

interface TripAdvisorDebounceProps {
  handleSuggestionClick: () => void
  inputRef: HTMLInputElement
  onSelect: (placeId: string | undefined) => void
  searchQuery: string
}

const TripAdvisorDebounce: React.FC<TripAdvisorDebounceProps> = ({
  searchQuery,
  onSelect,
  handleSuggestionClick,
  inputRef,
}) => {
  const [results, setResults] = useState<TripAdvisorPlace[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const fetchData = useCallback(async (query: string) => {
    if (!query || query === '') {
      setIsOpen(false)
      setResults([])
      return
    }
    try {
      const response: TripAdvisorResponse = await getTripAdvisorList({
        language: 'en',
        searchQuery: query,
      })

      if (response.data) {
        setResults(response.data)
        setIsOpen(true)
      } else {
        setResults([])
        setIsOpen(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [])

  const debouncedFetchData = useCallback(debounce(fetchData, 300), [])

  useEffect(() => {
    if (!searchQuery || searchQuery === '') {
      setIsOpen(false)
      setResults([])
    } else if (inputRef === document.activeElement) {
      debouncedFetchData(searchQuery)
    }
  }, [searchQuery, debouncedFetchData, inputRef])

  return (
    <div
      className={styles.debounceContainer}
      style={{ display: `${isOpen ? 'flex' : 'none'}` }}
    >
      {results &&
        results.map((place) => (
          <div
            className={styles.placeContainer}
            key={place.location_id}
            onClick={() => {
              onSelect(place?.location_id)
              handleSuggestionClick()
              setIsOpen(false)
            }}
          >
            <div className={styles.placeInfoContainer}>
              <h3 className={styles.placeName}>{place.name}</h3>
              <p
                className={styles.placeAddress}
              >{`${place?.address_obj?.street1},${place?.address_obj?.country}`}</p>
            </div>
          </div>
        ))}
    </div>
  )
}

export default TripAdvisorDebounce
