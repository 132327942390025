import { AnalyticsApi } from '../analyticsApi'

export interface TripAdvisorPlace {
  address_obj?: {
    address_string?: string
    city?: string
    country?: string
    postalcode?: string
    street1?: string
    street2?: string
  }
  location_id?: string
  name?: string
}

export interface TripAdvisorResponse {
  data?: TripAdvisorPlace[]
}

export const getTripAdvisorList = async (query: {
  language: string
  searchQuery: string
}): Promise<TripAdvisorResponse> => {
  const { data } =
    await AnalyticsApi.v1Private.tripadvisorSearchTripAdvisorList(query)

  return data
}
